// 社保信息配置
<template>

    <div class="socialSecurityInfo baseBg">

        <el-card class="blueLine mb30"
                 shadow="never">
            <div slot="header">
                <span class="b">社保信息配置</span>
                <el-button type="success"
                           class="flr"
                           size="mini"
                           @click="save">保存</el-button>
            </div>
            <div class="warp">
                <el-form :model="form"
                         ref="form"
                         :rules="formRules"
                         label-position="top"
                         :inline="false">
                    <el-row :gutter="20">
                        <el-col :span="8"
                                v-for="(item,idx) in prefixArr"
                                :key="idx">
                            <div class="popItemBox">
                                <div class="title">{{prefixCNArr[idx]}}：</div>
                                <div class="infoBox">
                                    <el-row :gutter="10">
                                        <el-col :span="8">
                                            <el-form-item label="最低缴纳基数：">
                                                <el-input-money v-model="form[item+'Base']"></el-input-money>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="个人比例：（%）">
                                                <el-input-number :min="0"
                                                                 :max="100"
                                                                 :step="1"
                                                                 v-model="form[item+'StaffPercent']"
                                                                 :controls="false">
                                                </el-input-number>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="单位比例：（%）">
                                                <el-input-number :min="0"
                                                                 :max="100"
                                                                 :step="1"
                                                                 v-model="form[item+'CompanyPercent']"
                                                                 :controls="false">
                                                </el-input-number>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                </el-form>
            </div>
        </el-card>
    </div>

</template>

<script>
import ElInputMoney from "../../../components/form/elInputMoney";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
export default {
    name: "socialSecurityInfo",

    props: {},

    components: {
        ElInputMoney,
    },

    data() {
        return {
            form: {},
            formRules: {},
            prefixArr: [
                "endowmentInsurance", // 养老保险
                "medicalInsurance", // 医疗保险
                "unemploymentInsurance", // 失业保险
                "employmentInjuryInsurance", // 工伤保险
                "birthInsurance", // 生育保险
                "accumulationFund", // 公积金
            ], // 保存字段前缀
            prefixCNArr: [
                "养老保险", // endowmentInsurance
                "医疗保险", // medicalInsurance
                "失业保险", // unemploymentInsurance
                "工伤保险", // employmentInjuryInsurance
                "生育保险", // birthInsurance
                "公积金", // accumulationFund
            ], // 保存字段前缀
        };
    },

    created() { },

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".warp"),
            });
            API.getSocialSecurity()
                .then((res) => {
                    loading.close();
                    this.form = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 保存
        save() {
            let loading = Loading.service({
                target: document.querySelector(".warp"),
            });
            API.saveSocialSecurity(this.form)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>
